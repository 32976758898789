'use client';

import usePreviewModal from '@/hooks/use-preview-modal';
import Gallery from '@/components/gallery';
import Info from '@/components/info';
import { Modal } from '@/components/ui/modal';

const PreviewModal = () => {
  const previewModal = usePreviewModal();
  const product = usePreviewModal(state => state.data);

  if (!product) {
    return null;
  }

  return (
    <Modal open={previewModal.isOpen} onClose={previewModal.onClose}>
      <div className='grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8'>
        <div className='sm:col-span-4 lg:col-span-5 m-8'>
          <Gallery product={product} />
        </div>
        <div className='sm:col-span-8 lg:col-span-7'>
          <Info data={{product: product, quantity: 1}} />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
